//BASIC ELEMENTS ------------------------------------------------------ //

@if $type == sp{
	*{box-sizing:border-box;}
}

.forPC{
	@if $type == sp{
		display: none!important;
	}
}
.forSP{
	@if $type == pc{
		display: none!important;
	}
}

html{
	overflow-y:scroll;
	width: 100%;

	@if $type == sp{
		-webkit-overflow-scrolling: touch;
	}
}

body{
	min-width: 990px;
	font-family: $ff;
	font-weight: 500;
	background-color: #fff;
	color: $c_text;
	font-size: 14px;
	line-height: 1.5;
	cursor:auto;
	height: 100%;
	-webkit-text-size-adjust: none;

	@if $type == sp{
		width: 100%;
		min-width: 320px;
		position: relative;
		background: #fff;
	}
}

img{
	border: none;
	// vertical-align: top;

	@if $type == sp{
		max-width: 100%;
	}
}

a{
	color: $c_href;
	cursor: pointer;
	&:visited{
		color: $c_href;
	}
	&:hover{
		@if $type == pc{
			color: $c_hov;
		}
	}
	&:active{
		color: $c_hov;
	}
}
a.red{
	color: $c_hov;
	&:hover{
		@if $type == pc{
			opacity: 0.8;
		}
	}
}
img{
	height: auto;
}

ul li{
	list-style: none;
}

sub{
	position: relative;
	top: -3px;
	font-size: 10px;
}

.underline{
	text-decoration: underline!important;
}

//--------------------------------------------------------------------------------
// common Style
//--------------------------------------------------------------------------------

.clear{
	display: block;
	height:0px;
	clear:both;
}

.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

/* Hides from IE-mac \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* End hide from IE-mac */

* html .clear{
	overflow:hidden;
}
.alt {
	position:absolute;
	top:-5000px;
	left:-999px;
}
.floatLeft{float:left;}
.floatRight{float:right;}
.clearLeft{clear:left;}
.clearRight{clear:right;}
.clearboth{clear:both;}
.pc_floatLeft{float:left;}
.pc_floatRight{float:right;}

.alignRight{text-align:right!important;}
.alignCenter{text-align:center!important;}
.alignLeft{text-align:left!important;}
.pc_alignRight{text-align:right!important;}
.pc_alignCenter{text-align:center!important;}
.pc_alignLeft{text-align:left!important;}

.vaT{ vertical-align:top!important;}
.vaM{ vertical-align:middle!important;}
.vaB{ vertical-align:bottom!important;}

.mLRa{margin-left:auto!important; margin-right: auto!important;}
.mLR0{margin-left:0!important; margin-right: 0!important;}

.mT0{margin-top:0px!important;}
.mT5{margin-top:5px!important;}
.mT10{margin-top:10px!important;}
.mT15{margin-top:15px!important;}
.mT20{margin-top:20px!important;}
.mT30{margin-top:30px!important;}
.mT50{margin-top:50px!important;}
.mT70{margin-top:70px!important;}

.mTm10{margin-top:-10px!important;}
.mTm30{margin-top:-30px!important;}
.tm10{position: relative; top:-10px!important;}

.mL0{margin-left:0px!important;}
.mL5{margin-left:5px!important;}
.mL10{margin-left:10px!important;}
.mL15{margin-left:15px!important;}
.mL20{margin-left:20px!important;}
.mL30{margin-left:30px!important;}

.mR0{margin-right:0px!important;}
.mR5{margin-right:5px!important;}
.mR10{margin-right:10px!important;}
.mR15{margin-right:15px!important;}
.mR20{margin-right:20px!important;}
.mR30{margin-right:30px!important;}
.mR1p{margin-right: 1%!important;}
.mR2p{margin-right: 2%!important;}
.mR3p{margin-right: 3%!important;}

.mB0{margin-bottom:0px!important;}
.mB1{margin-bottom:1px!important;}
.mB5{margin-bottom:5px!important;}
.mB10{margin-bottom:10px!important;}
.mB15{margin-bottom:15px!important;}
.mB20{margin-bottom:20px!important;}
.mB30{margin-bottom:30px!important;}
.mB40{margin-bottom:40px!important;}
.mB50{margin-bottom:50px!important;}
.mB70{margin-bottom:70px!important;}

.mBm50{margin-bottom: -50px!important;}

.pT0{ padding-top:0px!important;}
.pT5{ padding-top:5px!important;}
.pT10{ padding-top:10px!important;}
.pT20{ padding-top:20px!important;}
.pT30{ padding-top:30px!important;}
.pT50{ padding-top:50px!important;}

.pR0{ padding-right:0px!important;}
.pR3{ padding-right:3px!important;}
.pR5{ padding-right:5px!important;}
.pR10{ padding-right:10px!important;}
.pR20{ padding-right:20px!important;}
.pR30{ padding-right:30px!important;}

.pB0{ padding-bottom:0px!important;}
.pB10{ padding-bottom:10px!important;}
.pB15{ padding-bottom:15px!important;}
.pB20{ padding-bottom:20px!important;}
.pB30{ padding-bottom:30px!important;}
.pB50{ padding-bottom:50px!important;}

.pL0{ padding-left:0px!important;}
.pL8{ padding-left:8px!important;}
.pL10{ padding-left:10px!important;}
.pL20{ padding-left:20px!important;}
.pL30{ padding-left:30px!important;}

.pA10{ padding: 10px!important;}
.pA35{ padding: 35px!important;}

.tIndent0{ text-indent: 0!important;}

.pc_inlineB{ display: inline-block!important;}

.inner{
	width: $site_w;
	margin: 0 auto;
}

.underL{
	text-decoration: underline;
}

/* WIDTH ------------------------- */

.wAuto{ width: auto!important;}

.w80{ width: 80px!important;}
.w90{ width: 90px!important;}
.w100{ width: 100px!important;}
.w160{ width: 160px!important;}
.w185{ width: 185px!important;}
.w190{ width: 190px!important;}
.w200{ width: 200px!important;}
.w270{ width: 270px!important;}
.w300{ width: 300px!important;}
.w400{ width: 400px!important;}

.w48p{ width: 48%!important;}
.w49p{ width: 49%!important;}
.w50p{ width: 50%!important;}
.w80p{ width:80%!important;}
.w99p{ width:99%!important;}
.w100p{ width:100%!important;}

/* FONT -------------------------- */

.size8{font-size:8px!important;}
.size10{font-size:10px!important;}
.size11{font-size:11px!important;}
.size12{font-size:12px!important;}
.size13{font-size:13px!important;}
.size14{font-size:14px!important;}
.size15{font-size:15px!important;}
.size16{font-size:16px!important;}
.size18{font-size:18px!important;}
.size20{font-size:20px!important;}
.size24{font-size:24px!important;}
.size27{font-size:27px!important;}
.size30{font-size:30px!important;}

.line_hS{ line-height: 1.5!important;}
.line_hM{ line-height: 1.7!important;}
.line_hL{ line-height: 2!important;}

.fontN{font-weight:normal!important;}
.bold{font-weight:bold;}

.brack{color:#333!important;}
.brack2{color:#111!important;}
.red{color:$c_hov!important;}
.red2{color:#FF0000!important;}

.hr{
	border-bottom: 1px solid #e4e4e4;
	width: 100%;
	height: 1px;
	overflow: hidden;
	margin-bottom: 30px;
	}

.noBorder{ border:none!important;}
.noBorderR{ border-right:none!important;}

.borderB{ border-bottom:1px solid #888;}
.borderB2{ border-bottom:1px solid #AAA; padding-bottom:10px;}

/* -------------------------- */

p{text-align:left;}
sup{vertical-align: super; font-size: 11px;}

.block{ display: block;}
.pc_block{display: block!important;}

/* form common
----------------------------------------*/

input[type="text"],
input[type="button"],
input[type="submit"],
input[type="number"]
textarea{
	font-family: $ff;
	font-weight: 500;
		@if $type == sp{
			font-size: 16px;
		}
	}
button{
	font-family: $ff;
	font-weight: 500;
	&:focus,
	&:active{
		border: none;
		outline:none;
	}
}
input[type="text"]{
	background: #FFF;
	border:1px solid #ccc;
	padding: 12px 10px;
}
textarea{
	height: 100px;
	}
input[type="radio"]{
	cursor: pointer;
	vertical-align: middle;
	position: relative;
	top: -2px;
	}
input[type="checkbox"]{
	cursor: pointer;
	vertical-align: middle;
	}

label,
.cb{
	cursor:pointer;
	}

label span,
label input{
	display:inline-block;
	padding-top:2px;
	vertical-align:middle;
	}

/* ------------------- form select ----------------------- */

select{
	padding:3px;
	font-family: $ff;
	font-weight: 500;
	height: 34px;
	border: 1px solid #ccc;
	border-radius: 3px;
	appearance: none;
	background-image: url(../images/common/select_bt.png);
	background-position: right 50%;
	background-color: #fff;
	font-size: 12px;
	padding-left: 12px;
	@if $type == sp{
		height: 40px;
		font-size: 14px;
		background-image: url(../images/sp/common/select_bt.png);
		background-size: 40px;
	}

	&.partsSelect{
		width: 300px;
	}

	&.hissu{
		@if $type == pc{
		}
		@if $type == sp{
			background-image: url(../images/sp/common/select_bt_hissu.png);
			background-size: 40px;
			border-color: $c_hov;
		}
	}
}//select

:placeholder-shown {
		@if $type == pc{
			color: #000;
		}
		@if $type == sp{
			color: #000;
			font-size: 14px;
		}
	}

/* Google Chrome, Safari, Opera 15+, Android, iOS */
::-webkit-input-placeholder{
	@if $type == pc{
		color: #000;
	}
	@if $type == sp{
		color: #000;
		font-size: 14px;
	}
}

/* Firefox 18- */
:-moz-placeholder{
	@if $type == pc{
		color: #000;
		opacity: 1;
	}
	@if $type == sp{
		color: #000;
		font-size: 14px;
		opacity: 1;
	}
}

/* Firefox 19+ */
::-moz-placeholder{
	@if $type == pc{
		color: #000;
		opacity: 1;
	}
	@if $type == sp{
		color: #000;
		font-size: 14px;
		opacity: 1;
	}
}

/* IE 10+ */
:-ms-input-placeholder{
	@if $type == pc{
		color: #000;
	}
	@if $type == sp{
		color: #000;
		font-size: 14px;
	}
}

.pl_sizeS:placeholder-shown{font-size: 11px;}
.pl_sizeS::-webkit-input-placeholder{font-size: 11px;}
.pl_sizeS::-moz-placeholder{font-size: 11px;}


.hidden{
	display:none!important;
	}

/* button
----------------------------------------*/
input.fbt{
	border:none;
	}

input.fbt:active{
	border:none;
	position:relative;
	top:1px;
	}

a.fbt,
span.fbt{
	/* box-shadow */
	text-decoration:none;
	}

a.fbt:active,
span.fbt:active{
	position:relative;
	top:1px;
	text-decoration:none;
	}

//--------------------------------------------------------------------------------
// Button Style
//--------------------------------------------------------------------------------

.btnWrap{
	text-align: center;
}

.commonBtn{
	width: 275px;
	height: 44px;
	color: #fff;
	position: relative;
	background:#333333;
	border:none;
	border-radius: 3px;
	margin: 0;
	font-size: 14px;
	cursor: pointer;

	&.tBtn{
		@if $type == pc{
		}
		@if $type == sp{
			height: 55px;
			padding-top: 17px;
		}
	}

	&.bt_p_sonota{
		@if $type == pc{
			background:url("../images/second/d1-btn-sonota.jpg") no-repeat 68px center #333;
			padding-right: 58px;
		}
		@if $type == sp{
		}
		&:hover{
			@if $type == pc{
			background:url("../images/second/d1-btn-sonota.jpg") no-repeat 68px center $c_hov;
			padding-right: 58px;
			}
		}
	}

	&+button,
	&+.commonBtn,{
		margin-left: 20px;
	}

	&+button.itemHeart{
		margin-left: 0px;
	}

	&:hover{
		@if $type == pc{
			background:$c_hov;
			color: #fff;
		}
	}

	&:after{
		display: block;
		position: absolute;
		width: 12px;
		height: 12px;
		border-top: solid 1px #fff;
		border-right: solid 1px #fff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		content: "";
		top: 50%;
		margin-top: -6px;
		right: 15px;
	}
	&:disabled{
		background:#cccccc;
		color: #fff;
	}
	&.btn_gray{
		background:#999999;
		color: #fff;
			&:hover{
				@if $type == pc{
					background:#333;
					color: #fff;
				}
			}
	}

	&.btn_red{
		background:$c_red;
		&:hover{
			@if $type == pc{
				opacity: 0.8;
				color: #fff;
			}
		}
	}

	&.btn_beige{
		background:$c_href;
		color: #fff;
		&:hover{
			@if $type == pc{
				background:$c_hov;
				color: #fff;
			}
		}
	}

	&.btn_s{
		width: auto;
		padding-left: 20px;
		padding-right: 45px;
		&.no_arrow{
			@if $type == pc{
				padding-right: 20px;
			}
			@if $type == sp{
				padding-right: 0;
			}
			&:after{
				display: none;
			}
		}
	}
	&.btn_back{
		&:after{
			display: block;
			position: absolute;
			width: 12px;
			height: 12px;
			border-top: none;
			border-right: none;
			border-bottom: solid 1px #fff;
			border-left: solid 1px #fff;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			content: "";
			top: 50%;
			margin-top: -6px;
			left: 15px;
		}
	}
	&.no_arrow{
		@if $type == pc{
			padding-right: 0px;
		}
		@if $type == sp{
			padding-right: 0;
		}
		&:after{
			display: none;
		}
	}

	&.downArrow{
		&:after{
			border-top: none;
			border-right: solid 1px #fff;
			border-bottom: solid 1px #fff;
			border-left: none;
			margin-top: -11px;
		}
	}
}

.lineBtn{
	width: 275px;
	height: 44px;
	color: $c_href;
	position: relative;
	border-radius: 3px;
	margin: 0;
	font-size: 14px;
	cursor: pointer;
	border: 1px solid $c_href;
	background: #fff;

	&+button{
		margin-left: 20px;
	}

	&.btn_s{
		height: 34px;
		padding-left: 10px;
		padding-right: 10px;
		width: auto;
	}

    &:hover{
    	@if $type == pc{
	        color: $c_hov;
	        border: 1px solid $c_hov;
    	}
    }

}

a.commonBtn{
	display: inline-block;
	padding-top: 12px;
	text-align: center;
	vertical-align: middle;
	text-decoration: none;
	color: #fff;
	&.disabled{
		background:#cccccc;
		color: #fff;
	}
}
a.lineBtn{
	display: inline-block;
	padding-top: 6px;
	text-align: center;
	vertical-align: middle;
	text-decoration: none;
}

// icon
//--------------------------------------------

//--------------------------------------------------------------------------------
// 共通部分
//--------------------------------------------------------------------------------



.footerSec2_tit,
.min_tit{
	display: inline-block;
	font-family: $ff_min;
	font-size: 18px;
	border-bottom: 1px solid #999;
	padding-bottom: 3px;
	margin-bottom: 15px;
	font-weight: normal;
}

.box_sdw{
	box-shadow: 0px 5px 5px 0px rgba(204,204,204,0.8);
	padding-bottom: 20px;
	margin-bottom: 30px;
}
.box_sdw2{
	box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.9);
	padding-bottom: 20px;
	margin-bottom: 30px;
}
.box_line{
	border-bottom: 1px solid #ccc;
	padding-bottom: 30px;
	margin-bottom: 30px;
	margin-left: 10px;
	margin-right: 10px;
}

/*検索ブロック
----------------------------------------*/

.searchWrap{
	@if $type == pc{
		font-size: 0;
	}
	@if $type == sp{
		margin: 0 10px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.sIn{
		@if $type == pc{
			font-size: 14px;
			width: 235px;
			display: inline-block;
			position: relative;
			margin-bottom: 20px;
		}
		@if $type == sp{
			flex-basis: 49%;
			width: 49%;
			font-size: 14px;
			display: inline-block;
			position: relative;
			margin-bottom: 5px;
		}

		&.s_areaSelect_wrap{
			@if $type == pc{
				position: relative;
			}
		}

		.s_areaSelect{
			border-color: $c_hov;
		}

		.s_areaSelect + span{
			@if $type == pc{
				position: absolute;
				top: 72px;
				left: 0;
				display: block;
				width: 100%;
				background: $c_hov;
				color: #fff;
				text-align: center;
				padding: 5px 0;
				z-index: 2;
				&:after{
					display: block;
					content: "";
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-top: 10px solid $c_hov;
					position: absolute;
					top: -17px;
					left: 50%;
					margin-left: -7px;
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
			@if $type == sp{
				position: absolute;
				top: 56px;
				left: 0;
				display: block;
				width: 100%;
				background: $c_hov;
				color: #fff;
				text-align: center;
				padding: 5px 0;
				z-index: 2;
				&:after{
					display: block;
					content: "";
					width: 0;
					height: 0;
					border: 10px solid transparent;
					border-top: 10px solid $c_hov;
					position: absolute;
					top: -17px;
					left: 50%;
					margin-left: -7px;
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}

		label{
			display: inline-block;
			font-size: 13px;
			color: #333;
			cursor: default;
		}
		&.sInL{
			@if $type == pc{
				width: 480px;
				text-align: left;
			}
			@if $type == sp{
				flex-basis: 100%;
				width: 100%;
			}
			select{
				@if $type == pc{
					width: 235px;
					margin-top: 5px;
				}
			}
		}
		&.mR10{
			margin-right: 10px;
		}
		&.mR30{
			margin-right: 30px;
		}
		select{
			@if $type == pc{
				width: 100%;
				margin-top: 5px;
				border:1px solid #cccccc;
                font-size: 14px;
			}
			@if $type == sp{
				width: 100%;
				margin-top: 5px;
			}
		}
		input[type="text"]{
			margin-top: 5px;
			width: 100%;
			padding: 4px 10px;
			height: 34px;
			border: 1px solid #cccccc;
			border-radius: 3px;
			position: relative;
			top: 1px;
			color: #000;
			@if $type == sp{
				height: 40px;
			}
		}
		.ico_must{
			background-color: $c_hov;
			color: #fff;
			font-size: 11px;
			display: inline-block;
			padding: 2px 5px;
			float: right;
			border-radius: 3px;
		}
	}
}
.select_alert{
	@if $type == pc{
	}
	@if $type == sp{
		display: block;
		width: auto;
		background: $c_hov;
		color: #fff;
		text-align: center;
		padding: 5px 0;
		margin: 7px 10px 0;
	}
}

/* カレンダー
---------------------------------------- */

#ui-datepicker-div{
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
	padding: 7px;
}

.ui-datepicker .ui-datepicker-title{
	font-weight: normal;
}

.ui-widget-header{
	background:#a1967a;
}

.ui-datepicker-group:nth-child(1){
	padding-right: 3px;
	table,
	.ui-datepicker-header{
		width:242px;
	}
}
.ui-datepicker-group:nth-child(2){
	padding-left: 3px;
	table,
	.ui-datepicker-header{
		width:243px;
	}
}

#ui-datepicker-div{
	table,
	.ui-datepicker-header{
		@if $type == sp{
			width:243px;
		}
	}
}

.ui-datepicker .ui-datepicker-header{
	border-radius: 0;
}

.ui-datepicker-group .ui-datepicker-header{
	margin: 7px 4px 0px;
}

.ui-datepicker th{
	background:#bcb299;
	color: #fff;
	font-size: 10px;
	font-weight: normal;
}
.ui-datepicker td a{
	font-weight: normal!important;
}

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-next:hover{
	background: none;
	right: 0;
	top: 0;
	cursor: pointer;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-prev:hover{
	background: none;
	left: 0;
	top: 0;
	cursor: pointer;
}

.ui-datepicker .ui-datepicker-next span{
	background: none;
	&:after{
			display: block;
			position: absolute;
			width: 8px;
			height: 8px;
			border-top: none;
			border-right: none;
			border-top: solid 1px #fff;
			border-right: solid 1px #fff;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			content: "";
			top: 50%;
			margin-top: -2px;
			left: 5px;
		}
}
.ui-datepicker .ui-datepicker-prev span{
	background: none;
	&:after{
			display: block;
			position: absolute;
			width: 8px;
			height: 8px;
			border-top: none;
			border-right: none;
			border-bottom: solid 1px #fff;
			border-left: solid 1px #fff;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			content: "";
			top: 50%;
			margin-top: -2px;
			left: 5px;
		}
}

.ui-datepicker .ui-datepicker-next:hover span:after,
.ui-datepicker .ui-datepicker-prev:hover span:after{
	border-width: 2px;
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary{
	opacity: 0.4;
}

/* --------------------------------------------------------------------------------
--------------------------------------------------------------------------------
 header
--------------------------------------------------------------------------------
-------------------------------------------------------------------------------- */

header{
@if $type == pc{
	width: 100%;
	min-width: $site_w;
	position: absolute;
	height: 110px;
	top: 0;
	left: 0;
	z-index: 9999;

	.logo{
		position: absolute;
		z-index: 100;
	}
	.logo a{
		display: block;
		overflow: hidden;
		text-indent: -5000px;
		width: 100px;
		height: 110px;
		display: block;
		background-image: url(../images/common/logo.png);
	}

	.headerIn{
		width: 100%;
		min-width: 990px;
		margin: 0 auto;
		height: 110px;
		position: relative;

		.headerMenu1{
			width: 100%;
			height: 50px;
			background-color: rgba(#fff, .9);

			.headerItems{
				float: right;
				height: 50px;
				padding-top: 20px;
				vertical-align: top;
			}

			.headAreaSelect{
				width: 125px;
				display: inline-block;
				vertical-align: top;
				margin-right: 5px;
				display: none;

				select{
					width: 125px;
					height: 40px;
					border: 1px solid #ccc;
					border-radius: 3px;
					appearance: none;
					background-image: url(../images/common/select_bt_head.png);
					background-position: right 50%;
					font-size: 12px;
					padding-left: 12px;
				}
			}

			.h_searchWrap{
				display: inline-table;
				height: 40px;
				width : 30% ; /* IE8以下とAndroid4.3以下用フォールバック */
				width : -webkit-calc(100% - 690px) ;
				width : calc(100% - 690px) ;
				position: absolute;
				right: 20px;
				top: 60px;

				span{
					display: table-cell;
					height: 40px;
					vertical-align: top;

					&:nth-child(2){
						width: 40px;
					}

					input[type = text]{
						width: 100%;
						height: 40px;
						border: 1px solid #ccc;
						border-width: 1px 0 1px 1px;
						border-radius: 3px 0 0 3px;
						font-size: 12px;
						padding-right: 0;
					}

					button{
						width: 40px;
						height: 40px;
						border: none;
						background-color: #333;
						border-radius: 0 3px 3px 0;
						cursor: pointer;
						&:hover{
							background-color: $c_hov;
						}
					}
				}
			}

			ul{
				display: inline-block;
				vertical-align: top;
				padding-top: 0px;
				padding-right: 20px;

				li{
					display: inline-block;
					margin-left: 20px;
					white-space: nowrap;
					height: 20px;

					&:first-child{
						margin-left: 15px;
					}

					& > a{
						display: inline-block;
						height: 20px;
						line-height: 20px;
						text-decoration: none;

						&:before{
							content: "";
							display: inline-block;
							width: 20px;
							height: 20px;
							vertical-align: middle;
							margin-right: 3px;
							background-repeat: no-repeat;
							background-position: 0 0;
						}
					}

					$values: ico_clock, ico_heart, ico_cart, ico_login;
					@each $value in $values{
						$key: index($values, $value);
						&:nth-of-type(#{$key}){
							& > a{
								&:before{
									background-image: url(../images/common/#{nth($values, $key)}.png);
								}
								&:hover{
									&:before{
										background-image: url(../images/common/#{nth($values, $key)}_on.png);
									}
								}
							}
						}
					}
					&:nth-child(3).on a{
						color: $c_hov;
						&:before{
							background-image: url(../images/common/ico_cart_on.png);
						}
					}

			.cartCount{
				font-size: 13px;
				display: inline-block;
				line-height: 1;
				color: #fff;
				background-color: #b60000;
				line-height: 18px;
				height: 18px;
				text-align: center;
				border-radius: 9px;
				padding: 1px 5px 0;
				margin-left: 3px;
			}
				}
			}
		}//headerMenu1
		.headerMenu2{
			width: 100%;
			height: 60px;
			background-color: #fff;
			padding-left: 110px;

			.gMenu{
				display: table;

				&>li{
					display: table-cell;
					height: 60px;
					position: relative;
					opacity: 1;

					&.active > a{
						color: $c_hov;
					}

					&.hov{
						background-color: #ece7dc;

						&:after{
							content: "";
							display: block;
							width: 15px;
							height: 15px;
							background-color: #fff;
							transform: rotate(45deg);
							position: absolute;
							bottom: -7px;
							left: 50%;
							margin-left: -7px;
						}
					}

					&.parent{
					}

					&>a{
						display: block;
						padding: 0 10px;
						position: relative;
						height: 60px;
						line-height: 60px;
						color: #333;
						text-decoration: none;

						&.noChild{
							&:hover{
								background-color: #ece7dc;
							}
						}
					}

					.gSub{
						position: absolute;
						width: 100vw;
						background-color: rgba(#fff, 1);
						left: -110px;
						padding-left: 110px;
						display: none;

						.gSubInner{
							display: flex;
							border-left: 1px solid #b60000;

							ul{
								width: 180px;
								margin-left: 20px;
								padding: 10px 0;

								li{
									padding: 10px 0;

									a{
										font-size: 13px;
										line-height: 1;

										&:hover{
											text-decoration: none;
										}
									}

									&:nth-of-type(1){
										border-bottom: 1px solid #999;
										font-size: 18px;
										font-weight: 700;
										color: #333;
										line-height: 1;
									}
								}
							}
						}
					}
				}
			}
		}
	}//headerIn

	&.sclTop{
		position: fixed;
		top: 0!important;
		height: 60px;
		background: #fff;
		box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);


		.headerIn .headerMenu1 .headerItems{
			padding-top: 8px;
		}

		.headerIn .headerMenu1 .headAreaSelect{
			display: inline-block;
			height: 40px;
			padding-top: 2px;
			select{
				height: 40px;
			}
		}

		.headerIn .headerMenu1 .h_searchWrap{
			display: inline-table;
			height: 40px;
			width: 265px;
			position: static;
			padding-top: 2px;
			input:focus:placeholder-shown { color: transparent!important;}
			input:focus::-webkit-input-placeholder{ color: transparent!important;}
			input:focus:-moz-placeholder{ color: transparent!important;}
			input:focus::-moz-placeholder{ color: transparent!important;}
			input:focus:-ms-input-placeholder{ color: transparent!important;}
		}

		.headerIn .headerMenu1 ul{
			padding-top: 12px;
		}

		.logo{
			margin: 6px 0 0 5px;
		}
		.logo a{
			width: 135px;
			height: 47px;
			background-image: url(../images/common/logo2.png);
		}

		.headerMenu2{
			height: 0;
			overflow: hidden;
		}
	}//&.sclTop

	&.simpleHead{
		width: 100%;
		min-width: $site_w;
		background:#fff;
		overflow: hidden;
		height: 60px;
		position: static;
		margin: 0 auto;
		.headerMenu1 ul{
			float: right;
			padding-top: 20px;
		}
		.headerIn{
			height: auto;
		}
		.logo{
			display: block;
			width: 143px;
			height: 47px;
			background-image: url(../images/common/logo2.png);
			margin-top: 6px;
			margin-left: 5px;
		}
	}

	&.loginHead{
		width: 100%;
		min-width: $site_w;
		background:#fff;
		overflow: hidden;
		height: 60px;
		position: static;
		margin: 0 auto;
		.headerMenu1 ul{
			float: right;
			padding-top: 20px;
		}
		.headerIn{
			height: auto;
		}
		.logo{
			display: block;
			width: 143px;
			height: 47px;
			background-image: url(../images/common/logo2.png);
			margin-top: 6px;
			margin-left: 5px;
		}
		.headerIn .headerMenu1 ul li:nth-of-type(1){
			& > a{
				&:before{
					background-image: none;
				}
				&:hover{
					&:before{
						background-image: none;
					}
				}
			}
		}
		.headerIn .headerMenu1 ul li:nth-of-type(2){
			& > a{
				&:before{
					background-image: url(../images/common/ico_clock.png);
				}
				&:hover{
					&:before{
						background-image: url(../images/common/ico_clock_on.png);
					}
				}
			}
			&.active > a{
				color: $c_hov;
				&:before{
					background-image: url(../images/common/ico_clock_on.png);
				}
			}
		}
		.headerIn .headerMenu1 ul li:nth-of-type(3){
			& > a{
				&:before{
					background-image: url(../images/common/ico_heart.png);
				}
				&:hover{
					&:before{
						background-image: url(../images/common/ico_heart_on.png);
					}
				}
			}
			&.active > a{
				color: $c_hov;
				&:before{
					background-image: url(../images/common/ico_heart_on.png);
				}
			}
		}
	}
}//forPC

/* sp_header
----------------------------------------*/
@if $type == sp{
	width: 100%;
	height: 84px;
	padding-top: 30px;
	margin-top: -30px;
	overflow: hidden;
	background:#fff;
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	position: absolute;
	top: 0px;
	left: 0;
	z-index: 9999;

	.menuBtn{
		width: 54px;
		height: 54px;
		background:#fff;
		box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
		float: left;
		margin-right: 10px;
	}

	.logo{
		display: block;
		max-width: 110px;
		width: 30%;
		float: left;
		margin-top: 12px;
	}

	ul{
		width: 145px;
		float: right;
		margin-right: 5px;

		li{
			float: left;
			font-size: 10px;
			a{
				display: block;
				height: 50px;
				padding-top: 32px;
			}
			&.hm1{
				width: 45px;
				text-align: center;
				a{
					background:url("../images/sp/common/ico/rireki.png") no-repeat center 12px;
					background-size: 18px auto;
				}
			}
			&.hm2{
				width: 65px;
				text-align: center;
				a{
					display: block;
					background:url("../images/sp/common/ico/heart.png") no-repeat center 13px;
					background-size: 18px auto;
				}
				&.active{
					a{
						background:url("../images/sp/common/ico/heart_on.png") no-repeat center 13px;
						background-size: 18px auto;
						color: $c_hov;
					}
				}
			}
			&.hm3{
				width: 35px;
				text-align: center;
				a{
					display: block;
					background:url("../images/sp/common/ico/cart.png") no-repeat center 10px;
					background-size: 25px auto;
				}
			}
		}//li
	}//ul
	.h_searchWrap{
		display: none;
	}

	&.sclTop{

		.logo{
			display: none;
		}

		& > ul{
			width: 35px;

			.hm1{
				display: none;
			}
			.hm2{
				display: none;
			}
		}

		.h_searchWrap{
			float: left;
			display: table;
			height: 40px;
			width : 30% ; /* IE8以下とAndroid4.3以下用フォールバック */
			width : -webkit-calc(100% - 130px) ;
			width : calc(100% - 130px) ;
			margin-top: 7px;

			span{
				display: table-cell;
				height: 40px;
				vertical-align: top;

				&:nth-child(2){
					width: 40px;
				}

				input[type = text]{
					width: 100%;
					height: 40px;
					border: 1px solid #ccc;
					border-width: 1px 0 1px 1px;
					border-radius: 3px 0 0 3px;
					font-size: 12px;

					&:placeholder-shown{font-size: 12px;}
					&::-webkit-input-placeholder{font-size: 12px;}
					&::-moz-placeholder{font-size: 12px;}
					&:focus:placeholder-shown { color: transparent!important;}
					&:focus::-webkit-input-placeholder{ color: transparent!important;}
					&:focus:-moz-placeholder{ color: transparent!important;}
					&:focus::-moz-placeholder{ color: transparent!important;}
					&:focus:-ms-input-placeholder{ color: transparent!important;}
				}

				button{
					width: 40px;
					height: 40px;
					border: none;
					background-color: #333;
					border-radius: 0 3px 3px 0;
					img{
						width: 20px;
					}
				}
			}
		}
	}//&.sclTop
}//forSP
}// header

@if $type == sp{
	.open .overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10000;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
	}

	#drawer {
		background: #fff;
		position: fixed;
		top: 0;
		left: -250px;
		width: 250px;
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		-webkit-transition: all .2s;
		-moz-transition: all .2s;
		-o-transition: all .2s;
		-ms-transition: all .2s;
		transition: all .2s;
		z-index: 10001;
	}

	#drawer.open {
		-webkit-transform: translate3d(250px, 0, 0);
		-moz-transform: translate3d(250px, 0, 0);
		-o-transform: translate3d(250px, 0, 0);
		-ms-transform: translate3d(250px, 0, 0);
		transform: translate3d(250px, 0, 0);
	}

	.navHead{
		overflow: hidden;
		background:#fff;
		text-align: center;
		height: 54px;
		border-bottom: 1px solid #c6c6c6;
		position: relative;

		.spClose{
			width: 54px;
			height: 54px;
			background:#fff;
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
			float: left;
			margin-right: 40px;
		}
		.tit{
			display: block;
			width: 110px;
			float: left;
			margin-top: 12px;
		}
	}
	.spNaviList{
		&>div.m{
			border-bottom: 1px solid #cccccc;
			a{
				display: block;
				padding: 15px 10px 12px 15px;
				font-size: 14px;
				color: #333;
			}
			&.m1 a{padding-left: 40px; background: url("../images/sp/common/ico/login.png") no-repeat 13px center #f5f2ea; background-size: 18px; color: $c_href;}
			&.m2 a{padding-left: 40px; background: url("../images/sp/common/ico/rireki.png") no-repeat 13px center #f5f2ea; background-size: 18px; color: $c_href;}
			&.m3 a{padding-left: 40px; background: url("../images/sp/common/ico/heart.png") no-repeat 13px center #f5f2ea; background-size: 18px; color: $c_href;}
			&.m4 a{padding-left: 40px; background: url("../images/sp/common/ico/cart.png") no-repeat 13px center #f5f2ea; background-size: 18px; color: $c_href;}
			&.m5 a{padding-left: 40px; background: url("../images/sp/common/ico/book.png") no-repeat 13px center #f5f2ea; background-size: 18px; color: $c_href;}
		}
	}
	.navBottom{
		text-align: center;
		padding-top: 15px;
		& > div{
			display: inline-block;
			margin: 0 5px 10px;
			width: 115px;
		}
		& > div.inq{
			display: inline-block;
			width: 245px;
			margin-bottom: 0;
		}
	}

	&.simpleHead{
		position: relative;
		margin-bottom: -54px;
		top: 0!important;
	}
}//forSP


/* --------------------------------------------------------------------------------
--------------------------------------------------------------------------------
 footer
--------------------------------------------------------------------------------
-------------------------------------------------------------------------------- */

footer{
@if $type == pc{
	width: 100%;
	min-width: $site_w;
	background: #fff;

	.footerSec1{
		margin-bottom: 50px;
	}

	.footerSec1_list{
		display: flex;
		justify-content: space-between;

		li{
			flex-basis: 32%;
			width: 32%;
			position: relative;
			height: 170px;
			background: #fff;
			justify-content: space-between;
			box-sizing: border-box;
			transition: ease .5s;

			@include hov_line;
		}

		a{
			padding-left: 67px;
			height: 170px;
			overflow: hidden;
			display: block;
		}

		img:first-child{
			width: 100%;
		}

		img + img{
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		span{
			background: url(../images/common/arrow.png) no-repeat 98% center rgba(#fff,0.8);
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 13px 40px 13px 77px;
			color: #333;
			font-size: 12px;
			z-index: 1;
		}
	}

	.footerSec2{
		position: relative;
		padding-bottom: 20px;
		overflow: hidden;

		&:before{
			content: "";
			background: url(../images/common/footer_bg1.png) no-repeat center top;
			width: 200px;
			height: 92px;
			display: block;
			position: absolute;
			bottom: 35px;
			left: -13px;
		}

		&:after{
			content: "";
			background: url(../images/common/footer_bg2.png) no-repeat center top;
			width: 457px;
			height: 229px;
			display: block;
			position: absolute;
			bottom: 35px;
			right: -10px;
		}
	}

	.inner{
		display: table;
		table-layout: fixed;
		position: relative;
		z-index: 10;
	}

	.col{
		display: table-cell;
		vertical-align: top;
		border-right: 1px solid #ddd;
		padding: 20px 20px 0;

		&:first-child{
			border-left: 1px solid #ddd;
		}

		dt{
			font-size: 16px;
			border-bottom: 1px solid #999999;
			display: inline-block;
			width: 180px;
			margin-bottom: 10px;
		}

		dd{
			font-size: 12px;
			margin-bottom: 20px;
		}

		li{
			padding-left: 14px;
			text-indent: -14px;
			margin-bottom: 20px;

			&:before{
				content: "・";
			}
		}
	}

	.footerLogo{
		background: url(../images/common/f_bg.png) repeat center top;
		height: 200px;
		text-align: center;
		line-height: 200px;
		position: relative;
		overflow: hidden;

		.toTop{
			img{
				position: absolute;
				background: #fff;
				top: 20px;
				right: 110px;
				border-radius: 50%;
				padding: 15px;
			}

			&:hover{
				opacity: 0.8;
			}
		}

		&:before{
			content: "";
			background: url(../images/common/footer_bg3.png) no-repeat center top;
			width: 216px;
			height: 57px;
			display: block;
			position: absolute;
			top: 35px;
			left: 55px;
		}

		&:after{
			content: "";
			background: url(../images/common/footer_bg4.png) no-repeat center top;
			width: 269px;
			height: 110px;
			display: block;
			position: absolute;
			top: 90px;
			right: -4px;
		}
	}

	.footerBottom{
		background: #333;
		overflow: hidden;
		height: 50px;

		.cqlogo{
			float: left;
			margin-right: 40px;
		}

		ul{
			float: left;
			letter-spacing: -0.4em;
			padding-top: 15px;

			li{
				font-size: 12px;
				display: inline-block;
				letter-spacing: normal;
				margin-right: 25px;
				a:hover{
					@if $type == pc{
						color: #fff;
					}
				}
			}
		}

		small{
			float: right;
			font-size: 12px;
			padding: 15px 20px 0 0;
			color: $c_href;
		}
	}

}//forPC

/* sp_header
----------------------------------------*/
@if $type == sp{
	min-width: 320px;
	width: 100%;


	.footerSec1{
		margin-bottom: 30px;
	}
	.footerSec1_list{
		li{
			position: relative;
			height: 150px;
			background: #fff;
			margin-bottom: 10px;
			transition: ease .5s;

			@include hov_line;
		}

		a{
			padding-left: 67px;
			height: 150px;
			overflow: hidden;
			display: block;
		}

		img:first-child{
			width: 100%;
		}

		img + img{
			width: 75px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		span{
			background: rgba(#fff,0.8);
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 7px 20px 7px 85px;
			color: #333;
			font-size: 12px;
			z-index: 1;

			&:after{
				display: block;
				position: absolute;
				width: 12px;
				height: 12px;
				border-top: solid 1px #666666;
				border-right: solid 1px #666666;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				content: "";
				top: 50%;
				margin-top: -6px;
				right: 15px;
			}
		}
	}//.footerSec1_list

	.footerSec2{
		margin: 0 10px;

		.inner{
			.col{
				dl{
					margin-bottom: 10px;
					dt{
						background:#f5f2ea;
						font-family: $ff_min;
						font-size: 16px;
						color: #816d3f;
						font-weight: normal;
						text-align: center;
						padding: 10px 0;
						position: relative;

						&:after{
							display: block;
							position: absolute;
							width: 12px;
							height: 12px;
							border-top: solid 1px #816d3f;
							border-left: solid 1px #816d3f;
							-webkit-transform: rotate(-135deg);
							transform: rotate(-135deg);
							content: "";
							top: 50%;
							margin-top: -8px;
							right: 15px;
						}

						&.open{
							background:#a39678;
							color: #fff;
							font-weight: normal;
							text-align: center;
							padding: 10px 0;
							position: relative;

							&:after{
								display: block;
								position: absolute;
								width: 12px;
								height: 12px;
								border-top: solid 1px #fff;
								border-left: solid 1px #fff;
								-webkit-transform: rotate(45deg);
								transform: rotate(45deg);
								content: "";
								top: 50%;
								margin-top: -3px;
								right: 15px;
							}
						}//&.open
					}//dt
					dd{
						font-size: 12px;
						border: 1px solid #ccc;
						border-top: none;
						border-radius: 0 0 3px 3px;
						padding: 15px;
						display: none;
					}//dd
				}
			}
		}//.inner
	}//.footerSec2

	.footerLogo{
		background: url(../images/common/f_bg.png) repeat center top;
		height: 115px;
		text-align: center;
		position: relative;
		overflow: hidden;
		padding-top: 15px;

		.fLogo{
			position: relative;
			display: inline-block;
			width: 65px;
			z-index: 1;
		}

		.toTop{
			img{
				width: 50px;
				position: absolute;
				background: #fff;
				top: 10px;
				right: 10px;
				border-radius: 50%;
				padding: 15px;
			}

			&:hover{
				opacity: 0.8;
			}
		}

		&:before{
			content: "";
			background: url(../images/sp/common/footer_bg3.png) no-repeat center top;
			background-size: 100% auto;
			width: 115px;
			height: 50px;
			display: block;
			position: absolute;
			top: 35px;
			left: 10px;
		}

		&:after{
			content: "";
			background: url(../images/sp/common/footer_bg4.png) no-repeat center top;
			background-size: 100% auto;
			width: 150px;
			height: 60px;
			display: block;
			position: absolute;
			bottom: 0px;
			right: -10px;
		}
	}//.footerLogo

	.footerBottom{
		background: #333;
		overflow: hidden;
		height: 50px;

		.cqlogo{
			width: 120px;
			float: left;
			margin-top: 5px;
		}

		ul{
			float: right;
			width: 190px;
			letter-spacing: -0.4em;
			padding-top: 15px;
			padding-right: 10px;

			li{
				font-size: 12px;
				display: inline-block;
				letter-spacing: normal;
				margin-left: 10px;

				&:first-child{
					margin-left: 0;
				}
			}
		}
	}//.footerBottom

	& > small{
		clear: both;
		display: block;
		text-align: center;
		font-size: 12px;
		padding: 15px 20px 20px 0;
		color: $c_href;
	}

}//forSP
}//footer


main{
	@if $type == sp{
		padding-top: 54px;
		margin-bottom: 20px;
	}

	@if $type == pc{
		&.top110{
			padding-top: 110px;
		}
	}
}




.home_tokushu{
	min-width: $site_w;
	font-size: 0;
	@if $type == sp{
		margin-bottom: 20px;
	}
	.slick-slider{
		margin-bottom: 15px;
	}
	div.tokushu_list{
		@if $type == pc{
			margin-left: -15px;
			margin-right: -15px;
		}
		@if $type == sp{
		}
		.one{
			display: inline-block;
			box-sizing:border-box;
			margin: 15px;
			@if $type == pc{
			}
			@if $type == sp{
				margin: 0 0 10px;
			}

			&:last-child{
				margin-bottom: 0;
			}

			a{
				display: inline-block;
				position: relative;
			}
				@include hov_line;

			img{
				width: 100%;
			}
		}
	}
}



.home_search{
	@if $type == pc{
		width: $site_w;
		margin: 0 auto;
	}
	@if $type == sp{
		background: #fff;
	}
}

.moreSearchWrap{
	@if $type == sp{
		margin: 20px 10px 0;
	}
}

.searchWrapIn_genre,
.searchWrapIn_other{
	text-align: left;
	& > label{
		cursor: default;
	}
	.searchWrapIn_bg{
		margin-top: 5px;
		background:#efefef;
		padding: 30px 30px 10px;
		border-radius: 3px;
		@if $type == sp{
			margin-top: 0;
			padding: 15px 15px 0px;
		}
		ul{
			overflow: hidden;
			li{
				font-size: 14px;
				float: left;
				margin-right: 35px;
				margin-bottom: 20px;
				position: relative;
				height: 20px;
				overflow: hidden;
				@if $type == sp{
					margin-right: 20px;
				}

				input[type="checkbox"]{
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
				}
				label{
					display: block;
					padding-left: 27px;
					background:url("../images/common/checkbox.png") no-repeat 0 bottom;
					position: relative;
					z-index: 2;
					height: 20px;
					@if $type == sp{
						background:url("../images/sp/common/checkbox.png") no-repeat 0 bottom;
						background-size: 20px auto;
					}
				}
				input[type="checkbox"]:checked + label{
					background:url("../images/common/checkbox.png") no-repeat 0 0;
					@if $type == sp{
						background:url("../images/sp/common/checkbox.png") no-repeat 0 0;
						background-size: 20px auto;
					}
				}
			}
		}
	}
}

.btnWrap{
	text-align: center;
	padding-bottom: 60px;
	@if $type == sp{
		margin: 0 10px;
		padding: 10px 0px 20px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.sBtn{
		width: 275px;
		height: 44px;
		color: #fff;
		position: relative;
		background:#333333;
		border:none;
		border-radius: 3px;
		margin: 0 12px;
		font-size: 14px;
		cursor: pointer;
		@if $type == sp{
			flex-basis: 49%;
			width: 49%;
			margin: 0;
			font-size: 13px;
		}

		&:hover{
			background:$c_hov;
		}

		&:after{
			display: block;
			position: absolute;
			border-top: solid 1px #fff;
			border-right: solid 1px #fff;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			content: "";
			top: 50%;
			width: 12px;
			height: 12px;
			margin-top: -6px;
			right: 15px;
			@if $type == sp{
				display: inline-block;
				position: relative;
				top: -1px;
				right: 0;
				width: 8px;
				height: 8px;
				margin-left: 5px;
			}
		}
		&:disabled{
			background:#cccccc;
			color: #fff;
		}

        &.btn_red{
            background:$c_red;
            &:hover{
                opacity: 0.8;
                color: #fff;
            }
        }
	}
}


.search_toggle{
	display: inline-block;
	width: 175px;
	border-bottom: 1px solid $c_href;
	float: right;
	color: $c_href;
	margin-top: 15px;
	margin-left: -175px;
	font-size: 12px;
	position: relative;
	padding-left: 20px;
	padding-bottom: 10px;
	cursor: pointer;
	@if $type == sp{
		float: none;
		display: inline-block;
		width: auto;
		clear: both;
		margin: 0 auto;
		padding: 0 20px 10px 40px;
	}

	&:before{
		display: block;
		position: absolute;
		width: 10px;
		height: 10px;
		border-top: none;
		border-left: none;
		border-bottom: solid 1px $c_href;
		border-right: solid 1px $c_href;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		margin-top: -12px;
		left: 20px ;
		content: "";
		top: 50%;
		@if $type == sp{
		}
	}
	&.open{
		&:before{
			border-bottom: none;
			border-right: none;
			border-top: solid 1px $c_href;
			border-left: solid 1px $c_href;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			margin-top: -8px;
			left: 20px ;
		}
	}
	&:hover{
		@if $type == pc{
			color: $c_hov;
			border-color: $c_hov;
			&:before{
				border-color: $c_hov;
			}
		}//pc
	}
}

.hm3{
	position: relative;
	height: auto;
	&.on{
		height: 500px;
	}
}

.cart_modal{
	@if $type == pc{
		padding-top: 20px;
		width: 335px;
		height: 382px;
		position: absolute;
		top: 20px;
		right: -110px;
		display: none;
		background:url("../images/common/cart_modal_bg.png") no-repeat 0 2px;
		z-index: 10;
		&.disp{
			display: block!important;
		}
		&.hide{
			display: none;
		}
		.inner{
			position: relative;
			width: 335px;
			height: 382px;
			.close_btn{
				width: 25px;
			}
			.scroll_box{
				width: 310px;
				height: 200px;
				overflow-y:scroll;
				position: absolute;
				top: 16px;
				left: 15px;
				.cart_in_list{
					.list_one{
						position: relative;
						overflow: hidden;
						padding-bottom: 15px;
						border-bottom: 1px solid #ccc;
						margin-bottom: 15px;
						margin-right: 10px;
						&:last-child{
							margin-bottom: 0;
							border-bottom: none;
						}
						.img{
							width: 54px;
							float: left;
							img{
								width: 100%;
							}
						}
						.txt{
							width: 222px;
							float: right;
						}
						.shop_name{
							padding-right: 45px;
							white-space:normal;
							line-height: 1.3;
						}
						.del{
							position: absolute;
							bottom: 15px;
							right: 0;
							button.lineBtn.btn_s {
								height: 23px;
								padding-left: 6px;
								padding-right: 6px;
								width: auto;
								font-size: 11px;
							}
						}
					}//.list_one
				}//.cart_in_list
			}//.scroll_box
			.bottom_box{
				width: 323px;
				height: 117px;
				position: absolute;
				bottom: 29px;
				left: 6px;
				border-top: 1px solid #cccccc;
				padding-top: 10px;
				.b_txt{
					text-align: center;
					margin-bottom: 10px;
				}//b_txt
			}//.scroll_box
		}//.inner
		.cart_close{
			width: 31px;
			height: 31px;
			background: #666;
			position: absolute;
			top: 0;
			right: 0;
			border-radius: 20px;
			border:1px solid #666;
			&:before{
				display: block;
				content: "✕";
				color: #fff;
				width: 29px;
				height: 29px;
				border:1px solid #FFF;
				background: #000;
				border-radius: 15px;
				text-align: center;
				padding-top: 4px;
			}
		}
	}
}//.cart_modal

//modal
.modalIn{
	position: fixed;
	width: 320px;
	top: 50%;
	left: 50%;
	margin-top: -160px;
	margin-left: -160px;
	background:#fff;
	box-shadow: 0px 5px 5px 0px rgba(204,204,204,0.8);
	border:1px solid #cccccc;
	z-index:10001;
	@if $type == pc{
	}
	@if $type == sp{
	}
	.m_inner{
		position: relative;
	}
	.m_body{
		@if $type == pc{
			padding: 50px 15px 20px;
		}
		@if $type == sp{
			padding: 50px 15px 20px;
		}
		p{
			@if $type == pc{
				font-size: 16px;
			}
			@if $type == sp{
				font-size: 16px;
			}
		}
	}
	.m_closeBt{
		position: absolute;
		top: -15px;
		right: -15px;
		border:2px solid #6a6a6a;
		color:#6a6a6a;
		width: 31px;
		height: 31px;
		border-radius: 16px;
		font-size: 16px;
		text-align: center;
		background: #fff;
		padding-top: 2px;
		font-weight: bold;
		&:before{
			content: "";
			display: block;
			width: 2px;
			height: 15px;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			position: absolute;
			top: 6px;
			left: 13px;
			background:#6a6a6a;
		}
		&:after{
			content: "";
			display: block;
			width: 2px;
			height: 15px;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
			position: absolute;
			top: 6px;
			left: 13px;
			background:#6a6a6a;
		}
	}
}
.modalWrap {
	position:absolute;
	width:100%;
	height:100vh;
	top:0;
	left:0;
	display:none;
	.overLay {
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:1200px;
		z-index:10000;
	}
}